import React from 'react';
import {LegacyCard, Page} from '@shopify/polaris';

export default function Ingredient() {

    return (
        <Page narrowWidth>
            <LegacyCard sectioned>
            </LegacyCard>
        </Page>
    );
}
