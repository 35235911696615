import React, {useEffect, useState, useCallback} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {Frame} from '@shopify/polaris';
import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Sidebar from "./components/Sidebar";
import Recipe from "./pages/Recipe";
import Ingredient from "./pages/Ingredient";
import ReactGA from "react-ga4";
import BMICalculator from "./components/BMICalculator";
ReactGA.initialize("G-CPHX2M2E71");

const App = () => {
    const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

    const toggleMobileNavigationActive = useCallback(
        () =>
            setMobileNavigationActive(
                (mobileNavigationActive) => !mobileNavigationActive,
            ),
        [],
    );

    useEffect(() => {
        let userRating = document.querySelector('.app-user');
        let isAuthenticated = userRating.dataset.isAuthenticated;

        ReactGA.send({ hitType: "pageview", page: window.location.pathname});
    }, [])

    return (
        <BrowserRouter>
            <Frame
                topBar={<NavBar toggleMobileNavigationActive={toggleMobileNavigationActive} />}
//                navigation={<Sidebar />}
                showMobileNavigation={mobileNavigationActive}
                onNavigationDismiss={toggleMobileNavigationActive}
            >
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/recipe/:handle" element={<Recipe />} />
                    <Route path="/ingredient/:handle" element={<Ingredient />} />
                    <Route path="/bmi-calculator" element={<BMICalculator />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Frame>
        </BrowserRouter>
    );
};

export default App;