import React, {useEffect, useState} from 'react';
import {LegacyCard, Page} from '@shopify/polaris';
import {useParams} from "react-router-dom";
import axios from "axios";

export default function Recipe() {
    const { handle } = useParams();
    const [recipe, setRecipe] = useState(null);

    useEffect(() => {
        axios.get(`/api/recipe/${handle}`)
            .then(response => {
                setRecipe(response.data)
            })
            .catch(error => {
                console.log(error)
            })

    }, [])

    return (
        <Page narrowWidth>
            <LegacyCard sectioned title="Recipe">
                {recipe?.name}
            </LegacyCard>
        </Page>
    );
}