import React, {useState, useCallback} from 'react';
import { useLocation } from "react-router-dom";
import {Navigation} from '@shopify/polaris';
import {
    ArrowLeftMinor,
    HomeMajor,
    OrdersMajor,
    ConversationMinor,
} from '@shopify/polaris-icons';

export default function Sidebar() {
    const location = useLocation();

    return (
        <Navigation location={location.pathname}>
            <Navigation.Section
                items={[
                    {
                        label: 'Home',
                        url: '/'
                    },
                    {
                        label: 'Blog',
                        url: '/blog'
                    },
                    {
                        label: 'Recipes',
                    },
                ]}
            />
        </Navigation>
    );
}