import React, {useCallback, useState} from 'react';
import {Button, ButtonGroup, LegacyCard, LegacyStack, Page, TextField, VerticalStack, Text} from '@shopify/polaris';

export default function BMICalculator() {
    const [units, setUnits] = useState('metric');
    const [gender, setGender] = useState('male');
    const [bmi, setBmi] = useState(null);
    const [weightResult, setWeightResult] = useState('');
    const [data, setData] = useState({
        age: '',
        weight: '',
        height: '',
        height_feet: '',
        height_inches: '',
        weight_pounds: ''
    })

    const handleCalculate = useCallback(() => {
        if ('imperial' === units) {
            data.height = ((data.height_feet * 30.48) + (data.height_feet * 0.0254)).toFixed(1)
            data.weight = (data.weight_pounds * 0.453).toFixed(1)
        }

        let bmi = ((data.weight / data.height / data.height) * 10000).toFixed(1);

        if (bmi && bmi > 0 && bmi < 100) {
            setBmi(bmi)
            if (bmi <= 18.5) {
                setWeightResult('underweight');
            } else if (bmi > 18.5 && bmi <= 25) {
                setWeightResult('normal');
            } else if (bmi > 25 && bmi <= 30) {
                setWeightResult('overweight');
            } else {
                setWeightResult('obese');
            }
        }
    }, [data])


    return (
        <Page narrowWidth>
            <div style={{padding: "10px 0 30px 0"}}>
                <LegacyStack vertical>
                    <Text>
                        <b>Body Mass Index (BMI)</b> is a numerical value used to assess an individual's body weight relative to their height. It's a simple and widely used screening tool to categorize whether a person is underweight, normal weight, overweight, or obese. The BMI is calculated by dividing a person's weight in kilograms by the square of their height in meters.
                    </Text>
                </LegacyStack>
            </div>

            <LegacyCard title="BMI Calculator" primaryFooterAction={{
                content: "Calculate",
                onAction: handleCalculate
            }}>
                <LegacyCard.Header></LegacyCard.Header>
                <LegacyCard.Section subdued>
                <VerticalStack align="center" inlineAlign="center" gap="5">
                    {bmi &&
                        <div>
                            <div>Your BMI is <b>{bmi}</b> ({weightResult})</div>
                        </div>
                    }
                    <LegacyStack>
                        <LegacyStack.Item fill>Units</LegacyStack.Item>
                        <ButtonGroup segmented>
                            <Button pressed={'metric' === units} onClick={() => setUnits('metric')}>
                                Metric
                            </Button>
                            <Button pressed={'imperial' === units} onClick={() => setUnits('imperial')}>
                                Imperial
                            </Button>
                        </ButtonGroup>
                    </LegacyStack>

                    <LegacyStack>
                        <LegacyStack.Item fill>Gender</LegacyStack.Item>
                        <ButtonGroup segmented>
                            <Button pressed={'male' === gender} onClick={() => setGender('male')}>
                                Male
                            </Button>
                            <Button pressed={'female' === gender} onClick={() => setGender('female')}>
                                Female
                            </Button>
                        </ButtonGroup>
                    </LegacyStack>

                    <LegacyStack>
                        <LegacyStack.Item fill>Age</LegacyStack.Item>
                        <TextField
                            label="Age"
                            labelHidden
                            value={data?.age}
                            onChange={(value) => setData({...data, age: value})}
                            autoComplete="off"
                            align="right"
                            type="integer"
                        />
                    </LegacyStack>

                    {'metric' === units && <>
                        <LegacyStack>
                            <LegacyStack.Item fill>Height</LegacyStack.Item>
                            <TextField
                                label=""
                                labelHidden
                                value={data?.height}
                                onChange={(value) => setData({...data, height: value})}
                                autoComplete="off"
                                align="right"
                                suffix="cm"
                                type="number"
                            />
                        </LegacyStack>
                        <LegacyStack>
                            <LegacyStack.Item fill>Weight</LegacyStack.Item>
                            <TextField
                                label=""
                                labelHidden
                                value={data?.weight}
                                onChange={(value) => setData({...data, weight: value})}
                                autoComplete="off"
                                align="right"
                                suffix="kg"
                                type="number"
                            />
                        </LegacyStack>
                    </>}

                    {'imperial' === units && <>
                        <LegacyStack>
                            <LegacyStack.Item fill>Height</LegacyStack.Item>
                            <div style={{maxWidth: "150px", width: "150px"}}>
                                <TextField
                                    label=""
                                    labelHidden
                                    value={data?.height_feet}
                                    onChange={(value) => setData({...data, height_feet: value})}
                                    autoComplete="off"
                                    align="right"
                                    suffix="feet"
                                    type="number"
                                />
                            </div>
                            <div style={{maxWidth: "150px", width: "150px"}}>
                                <TextField
                                    label=""
                                    labelHidden
                                    value={data?.height_inches}
                                    onChange={(value) => setData({...data, height_inches: value})}
                                    autoComplete="off"
                                    align="right"
                                    suffix="inches"
                                    type="number"
                                />
                            </div>
                        </LegacyStack>
                        <LegacyStack>
                            <LegacyStack.Item fill>Weight</LegacyStack.Item>
                            <TextField
                                label=""
                                labelHidden
                                value={data?.weight_pounds}
                                onChange={(value) => setData({...data, weight_pounds: value})}
                                autoComplete="off"
                                align="right"
                                suffix="pounds"
                                type="number"
                            />
                        </LegacyStack>
                    </>}



                </VerticalStack>
                </LegacyCard.Section>
            </LegacyCard>

            <div style={{padding: "30px 0 30px 0"}}>
                <LegacyStack vertical>
                    <Text alignment="justify">
                        BMI ranges provide a rough classification of body weight in relation to height:
                    </Text>
                    <Text alignment="justify">
                        <b>Underweight (BMI below 18.5):</b> This category indicates a body weight that is lower than the healthy range for a given height. It might suggest inadequate nutrition or certain health concerns.
                    </Text>

                    <Text alignment="justify">
                        <b>Normal Weight (BMI 18.5 to 25):</b> Falling within this range generally indicates a balanced weight in proportion to height, often associated with better overall health outcomes.
                    </Text>

                    <Text alignment="justify">
                        <b>Overweight (BMI 25 to 30):</b> Individuals in this range have a higher body weight relative to their height, which might indicate increased health risks such as cardiovascular issues or diabetes.
                    </Text>

                    <Text alignment="justify">
                        <b>Obese (BMI over 30):</b> This category suggests a significantly higher body weight compared to height, raising the risk of various health problems, including heart disease, hypertension, and joint issues.
                    </Text>

                    <Text alignment="justify">
                        Despite its popularity, BMI has limitations. It doesn't distinguish between muscle and fat, so athletes with high muscle mass might be classified as overweight or obese, even if they have low body fat. Additionally, it doesn't consider factors like age, gender, bone density, and distribution of weight. Therefore, it's a basic indicator and not a definitive measure of overall health. Healthcare professionals often use BMI alongside other assessments to get a more comprehensive understanding of an individual's health status.
                    </Text>
                </LegacyStack>
            </div>
        </Page>
    );
}