import React from 'react';
import {EmptyState, LegacyCard, Page} from '@shopify/polaris';
import fitness from "../img/fitness.png"
import NewsletterSubscription from "../components/NewsletterSubscription";

export default function Home() {

    return (
        <Page narrowWidth>
            <LegacyCard sectioned>
                <EmptyState
                    heading="Become The Better Version Of Yourself"
                    image={fitness}
                >
                    <p>Take our Quiz to get a personal meal plan and achieve your weight goals!</p>
                </EmptyState>
            </LegacyCard>
            <NewsletterSubscription />
        </Page>
    );
}
