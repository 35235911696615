import React, {useState, useCallback} from 'react';
import {TopBar, ActionList} from '@shopify/polaris';

const NavBar = ({ toggleMobileNavigationActive }) => {
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [searchValue, setSearchValue] = useState('');


    const handleSearchResultsDismiss = useCallback(() => {
        setIsSearchActive(false);
        setSearchValue('');
    }, []);

    const handleSearchChange = useCallback((value) => {
        setSearchValue(value);
        setIsSearchActive(value.length > 0);
    }, []);

    const searchFieldMarkup = (
        <TopBar.SearchField
            onChange={handleSearchChange}
            value={searchValue}
            placeholder="Search"
            showFocusBorder
        />
    );

    return (
        <TopBar
            showNavigationToggle
            searchResultsVisible={isSearchActive}
            searchField={searchFieldMarkup}
            searchResults={<ActionList items={[]} />}
            onSearchResultsDismiss={handleSearchResultsDismiss}
            onNavigationToggle={toggleMobileNavigationActive}
        />
    );
}

export default NavBar;