import React, {useCallback, useState} from 'react';
import {Checkbox, FormLayout, InlineError, LegacyCard, TextField} from '@shopify/polaris';
import axios from "axios";

export default function NewsletterSubscription() {
    const [newsletter, setNewsletter] = useState(false);
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);

    const handleSubmit = useCallback(() => {
        if (!newsletter) {
            setErrorMessage('You have to accept terms and conditions');
            return;
        }

        axios.post('/api/newsletter/subscription/subscribe', {
            email: email
        })
            .then(response => {
                setErrorMessage('')
                setSuccess(true)
            })
            .catch(error => {
                if(error.response){
                    setErrorMessage(error.response.data[0]['message'])
                }
            })
    }, [email, newsletter]);

    const handleNewsLetterChange = useCallback(
        (value) => setNewsletter(value),
        [],
    );

    const handleEmailChange = useCallback((value) => setEmail(value), []);

    const formLayoutMarkup = <FormLayout>
        <TextField
            value={email}
            onChange={handleEmailChange}
            label="Email"
            type="email"
            id="email"
            autoComplete="email"
            placeholder="Enter your email address"
            helpText={
                <span>We’ll use this email address to send you newsletter.</span>
            }
        />
        <Checkbox
            label="I accept terms and conditions"
            checked={newsletter}
            onChange={handleNewsLetterChange}
        />
        <div style={{marginTop: '4px'}}>
            <InlineError message={errorMessage} fieldID="email" />
        </div>
    </FormLayout>;

    const thankYouMarkup = <p>Thank you for subscribing to the newsletter.</p>

    return (
        <LegacyCard title="Newsletter" sectioned primaryFooterAction={success ? null : {
            content: "Subscribe",
            onAction: handleSubmit
        }}>
            {success ? thankYouMarkup : formLayoutMarkup}
        </LegacyCard>
    );
}