import React from "react";
import { createRoot } from 'react-dom/client';
import '@shopify/polaris/build/esm/styles.css';
import {AppProvider} from "@shopify/polaris";
import enTranslations from '@shopify/polaris/locales/en.json';
import App from "./App";

const domNode = document.getElementById('root');
const root = createRoot(domNode);

root.render(<AppProvider i18n={enTranslations}>
    <App />
</AppProvider>);