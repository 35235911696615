import React from 'react';
import {LegacyCard, Page} from '@shopify/polaris';

export default function NotFound() {

    return (
        <Page narrowWidth>
            <LegacyCard sectioned title="Not found">
            </LegacyCard>
        </Page>
    );
}